<template>
  <div class="max-w-4xl mx-auto max-h-screen px-2 z-0 bg-custom p-2 rounded">
    <!-- <div class="message">{{ formMessage }}</div> -->

    <form @submit.prevent="findUser">
      <!-- <router-link to="join"> Back </router-link> -->
      <div
        class="w-full md:grid md:grid-cols-12 gap-8 bg-white md:h-96 p-4 rounded-md"
      >
        <div class="col-span-6 flex items-center">
          <img src="../../../assets/login.gif" />
        </div>
        <div class="col-span-6 flex flex-col justify-center gap-8 mt-8 md:mt-0">
          <h2 class="text-2xl text-brand">Confirm Login</h2>
          <div class="relative">
            <Input
              id="phone"
              :class="
                phone
                  ? 'border-green-700/10 text-green-700'
                  : 'border-brand-primary text-brand-primary'
              "
              class="w-full border focus:outline-none text-center margin: 0"
              type="number"
              v-model="phone"
              placeholder="Phone : 017XXXXXXXX"
              required
            />
            <div
              v-if="phone"
              class="absolute inset-0 bg-green-700/10 z-50 flex items-center justify-end px-4"
            >
              <span class="text-green-700 text-2xl">&#10003;</span>
            </div>
            <!-- <show-error
                            :errors="formErrors"
                            name="phone"
                            class="w-full max-w-xs mx-auto"
                        /> -->
          </div>

          <div class="block w-full relative">
            <Input
              type="number"
              v-model="code"
              class="w-full border focus:outline-none text-center"
              placeholder="OTP Code"
              autocomplete="off"
            />
          </div>
          <div class="flex items-center justify-center">
            <button
              type="submit"
              class="bg-brand text-white py-1.5 px-2 rounded-md flex items-center"
            >
              <spinner v-if="loading" />
              Confirm Login
            </button>
            <!-- <router-link to="/"> Back </router-link> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Input from "../../../components/input-new.vue";
import Spinner from "../../../components/spinner.vue";
// import ShowError from "../../../components/show-error.vue";
export default {
  components: {
    Input,
    Spinner,
  },
  data() {
    return {
      code: "",
      phone: this.$route.query.phone || "",
      loading: false,
    };
  },
  computed: {
    searchUser() {
      return this.$store.state.join.user;
    },
  },
  methods: {
    async findUser() {
      this.loading = true;
      this.formErrors = null;

      if (this.phone.length == 11) {
        await this.$store
          .dispatch("join/confirm", {
            data: {
              phone: this.phone,
              code: this.code,
            },
          })
          .then((r) => {
            this.$router.push({
              path: "/registration",
              query: { phone: this.phone },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
